export default [
    {
        header: 'Area',
        resource: 'Educational Resources',
        action: 'read',
    },
    {
        title: 'Groups',
        route: 'groups',
        icon: 'UsersIcon',
        resource: 'Groups',
        action: 'read',
    },
    {
        title: 'Dreamers',
        route: 'dreamers',
        icon: 'SmileIcon',
        resource: 'Dreamers',
        action: 'read',
    },
    {
        title: 'Learning Paths',
        route: 'learning-paths',
        icon: 'CompassIcon',
        resource: 'Learning Paths',
        action: 'read',
    },
    {
        title: 'Reports',
        route: 'reports',
        icon: 'FileTextIcon',
        resource: 'Reports',
        action: 'read',
    },
    {
        header: 'Educational resources',
        resource: 'Educational Resources',
        action: 'read',
    },
    // {
    //     title: 'Educational Resources',
    //     route: 'resources',
    //     icon: 'ArchiveIcon',
    //     resource: 'Educational Resources',
    //     action: 'read',
    // },
    {
        title: 'Tutorials',
        route: 'tutorials',
        icon: 'VideoIcon',
        resource: 'Educational Resources',
        action: 'read',
    },
    {
        title: 'Guides',
        route: 'guides',
        icon: 'BookmarkIcon',
        resource: 'Educational Resources',
        action: 'read',
    },
    {
        title: 'Catalog',
        route: 'catalogs',
        icon: 'BookOpenIcon',
        resource: 'Educational Resources',
        action: 'read',
    },  
    {
        title: 'Search learning paths',
        route: 'vc-learning-paths',
        icon: 'SearchIcon',
        resource: 'Virtual LP Catalog', 
        action: 'read',
    },
    {
        title: 'Search contents',
        route: 'vc-contents',
        icon: 'SearchIcon',
        resource: 'Virtual Content Catalog', 
        action: 'read',
    },
    // {
    //     title: 'Virtual Catalog',
    //     icon: 'BookOpenIcon',
    //     resource: 'Educational Resources', 
    //     action: 'read',
    //     children: [
    //         {
    //             title: 'Learning Paths',
    //             route: 'vc-learning-paths',
    //             resource: 'Educational Resources', 
    //             action: 'read',
    //         },
    //         {
    //             title: 'Contents',
    //             route: 'vc-contents',
    //             resource: 'Educational Resources', 
    //             action: 'read',
    //         },
    //     ],
    // },
    // {
    //             title: 'learning_path.create_heading',
    //             route: 'learning-paths-create',
    //             icon: 'FilePlusIcon',
    //             resource: 'Learning Paths',
    //             action: 'create',
    //         },
    //         {
    //             title: 'learning_path.assign_heading',
    //             route: 'learning-paths-assign',
    //             icon: 'CornerDownRightIcon',
    //             resource: 'Learning Paths',
    //             action: 'read',
    //         },
    {
        header: 'School Structure',
        resource: 'School Structure',
        action: 'read',
    },
    {
        title: 'Handle',
        route: 'school-upload',
        icon: 'SettingsIcon',
        resource: 'School Structure',
        action: 'read',
    },
]
